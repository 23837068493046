import { Square, Circle, Triangle } from "phosphor-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { colorPalette } from "../util/colorPalette";
import { ShapesType } from "../types/ShapesType";
import style from "./Shapes.module.css";

const Shapes = ({ reWriteShapes, onShapes }: ShapesType) => {
  const [shapes, setShapes] = useState<JSX.Element[]>([]);
  const [reWrite, setReWrite] = useState<boolean>(false);

  const getRandomShapes = useCallback((): JSX.Element[] => {
    const getRandomColor = (): string => {
      const colors = colorPalette();
      const random = colors[Math.floor(Math.random() * colors.length)];
      return random.color.hex;
    };

    const shapes = [
      <Square key={"square"} style={{ color: getRandomColor() }} size={32} />,
      <Circle key={"circle"} style={{ color: getRandomColor() }} size={32} />,
      <Triangle
        key={"triangle"}
        style={{ color: getRandomColor() }}
        size={32}
      />,
    ];

    setShapes(shapes);
    return shapes.sort(() => Math.random() - 0.5);
  }, []);

  const getShapes = useMemo(() => {
    setReWrite(reWrite);
    return <>{getRandomShapes()}</>;
  }, [getRandomShapes, reWrite]);

  useEffect(() => {
    onShapes(shapes);
  }, [shapes, onShapes]);

  useEffect(() => {
    setReWrite((prev) => !prev);
  }, [reWriteShapes]);

  return (
    <>
      <span className={style.shapes}>
        <span className={style.shapesContainer}>{getShapes}</span>
      </span>
    </>
  );
};

export default Shapes;
