import { useCallback, useState } from "react";
import Game from "../game/Game";
import GameOver from "../gameOver/GameOver";
import GameWon from "../gameWon/GameWon";
import Intro from "../intro/Intro";
import { GameStateEnum } from "../types/GameStateEnum";
import style from "./App.module.css";

function App() {
  const [gameState, setGameState] = useState<GameStateEnum>(
    GameStateEnum.uninitialized
  );

  const [time, setTime] = useState<Date>(new Date());
  const [clues, setClues] = useState<number>(0);

  const setResultTime = (milli: number) => {
    setTime(new Date(milli));
  };

  const setUsedClues = useCallback(() => {
    if (clues >= 5) {
      return;
    }
    setClues((prev) => prev + 1);
  }, [clues]);

  return (
    <div className={style.container}>
      {gameState === GameStateEnum.uninitialized && (
        <Intro onStartGame={() => setGameState(GameStateEnum.started)} />
      )}
      {gameState === GameStateEnum.started && (
        <Game
          onTimeIsUp={() => setGameState(GameStateEnum.gameover)}
          onSuccessfulLogin={() => setGameState(GameStateEnum.won)}
          onResultTime={setResultTime}
          onUsedClues={setUsedClues}
        />
      )}
      {gameState === GameStateEnum.won && (
        <GameWon time={time} usedClues={clues} />
      )}
      {gameState === GameStateEnum.gameover && <GameOver usedClues={clues} />}
    </div>
  );
}

export default App;
