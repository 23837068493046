import { useEffect, useState } from "react";
import { CardType } from "../types/CardType";
import style from "./Clues.module.css";

const Card = ({ clueCounter, name, msg }: CardType) => {
  const [flipped, setFlipped] = useState(false);
  const flipCard = () => {
    if (flipped) return;
    setFlipped(true);
    clueCounter(1);
    sessionStorage.setItem(name, "true");
  };

  useEffect(() => {
    if (sessionStorage.getItem(name) === "true") {
      setFlipped(true);
      clueCounter();
    }
  }, [name, clueCounter]);

  return (
    <div className={style.card}>
      <div className={`${style["inner"]} ${flipped && style.flipped} `}>
        <div onClick={flipCard} className={style.front}>
          <span className={style.header}>{name}</span>
        </div>
        <div className={style.back}>{msg}</div>
      </div>
    </div>
  );
};

export default Card;
